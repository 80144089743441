import React from "react";
import ElementsForm from "@/components/stripe/ElementsForm";
import {
  Flex,
} from "@chakra-ui/react";

const AddPaymentDetailModal = ({ isNext, setIsNext,mutate,userData}) => {
  return (<Flex w="100%" flexDirection="column"  maxH="500px">
      <ElementsForm
        isNext={isNext} 
        setIsNext={setIsNext}
        mutate={mutate}
        userData={userData}
      />
    </Flex>    
  );
};

export default AddPaymentDetailModal;
