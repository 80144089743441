import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Box
} from "@chakra-ui/react";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import StarEle from "@/utils/StarElement"
const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#333333",
      color: "#333333",
      // padding: "15px",
      borderRadius: "0.375rem",
      fontWeight: 500,
      lineHeight: "1.5",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#333333",
      },
      "::placeholder": {
        color: "#ddd",
      },
    },
    invalid: {
      iconColor: "#ef2961",
      color: "#ef2961",
    },
  },
};;
const CardInfo = ({ errors, handleCardElementChange, country, setCountry, formData, setFormData, handlePhoneElement }) => {
  const { userName, cardNumber, cardExpiry, cardCvc, phone_number, postalCode } = errors;
  const [isValidNumber, setIsValidNumber] = useState(false);

  return (
    <>
      <FormControl className="mb-1">
        <FormLabel className="form-label" htmlFor="cc-number">Card Number<StarEle/></FormLabel>
        <CardNumberElement
          id="cc-number"
          className="form-control"
          options={CARD_ELEMENT_OPTIONS}
          onChange={(e) => handleCardElementChange(e, 'cardNumber')}
        />
        {cardNumber &&
          <FormHelperText style={{ color: "red" }}>
            {cardNumber}
          </FormHelperText>
        }
      </FormControl>
      <Box className="flex items-start gap-4 mt-4 mb-1 edit-payment-expiry">
        <FormControl className="mb-4">
          <FormLabel className="form-label" htmlFor="expiry">Card Expiry<StarEle/></FormLabel>
          <CardExpiryElement
            id="expiry"
            className="form-control"
            options={CARD_ELEMENT_OPTIONS}
            onChange={(e) => handleCardElementChange(e, 'cardExpiry')}
          />
          {cardExpiry &&
            <FormHelperText style={{ color: "red" }}>
              {cardExpiry}
            </FormHelperText>
          }
        </FormControl>
        <FormControl className="mb-1">
          <FormLabel className="form-label" htmlFor="cvc">CVV<StarEle/></FormLabel>
          <CardCvcElement
            id="cvc"
            className="form-control"
            options={CARD_ELEMENT_OPTIONS}
            onChange={(e) => handleCardElementChange(e, 'cardCvc')}
          />
          {cardCvc &&
            <FormHelperText style={{ color: "red" }}>
              {cardCvc}
            </FormHelperText>
          }
        </FormControl>
      </Box>
      <FormControl className="mb-1">
        <FormLabel className="form-label">Card Holder's Name<StarEle/></FormLabel>
        <input
          className="form-control w-[100%]"
          onChange={(e) => {
            handleCardElementChange(e, 'userName')
          }}
          placeholder="Enter Name"
        />
        {userName &&
          <FormHelperText style={{ color: "red" }}>
            {userName}
          </FormHelperText>
        }
        </FormControl>
    </>
  )
}

export default CardInfo;