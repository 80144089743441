import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Button, Text, Stack } from "@chakra-ui/react";
import { fetchPostJSON } from "@/utils/api-helpers";
import {
  Elements,
  ElementsConsumer,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { addStripeCustomerId, payement, updatestripeData } from "@/lib/db";
import useSWR from "swr";
import fetcher from "@/utils/fetcher";
import CardInfo from "./CardInfo";
import useCustomToast from "hooks/useCustomToast";
import firebase from "firebase";
import { useAuth } from "@/lib/auth";
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#333333",
      color: "#333333",
      padding: "15px",
      borderRadius: "0.375rem",
      fontWeight: 500,
      lineHeight: "1.5",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#333333",
      },
      "::placeholder": {
        color: "#333333",
      },
    },
    invalid: {
      iconColor: "#ef2961",
      color: "#ef2961",
    },
  },
};

const ElementsForm = ({ userData,isNext, setIsNext,mutate }) => {
  const toast = useCustomToast();
  const {user}=useAuth();
  const [input, setInput] = useState({
    cardholderName: "",
  });
  const [payment, setPayment] = useState({ status: "initial" });
  const [disable,setDisable]=useState(false);
  const [price, setPrice] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const firestore = firebase.firestore();
  const db = firestore;
  useEffect(()=>{
    if(isNext){
      document.getElementById('submitForm')?.click();
      setIsNext(2,null);
    }
  },[isNext]);
  const PaymentStatus = ({ status }) => {
    switch (status) {
      case "processing":
      case "requires_payment_method":
      case "requires_confirmation":
        return (
          <Text fontWeight="bold" fontSize="xs">
            Processing...
          </Text>
        );

      case "requires_action":
        return (
          <Text fontWeight="bold" fontSize="xs">
            Authenticating...
          </Text>
        );

      case "succeeded":
        return (
          <Text fontWeight="bold" fontSize="xs">
            Payment Succeeded
          </Text>
        );

      case "error":
        return (
          <Text color="red.500" fontWeight="bold" fontSize="xs">
            Error - {errorMessage}
          </Text>
        );

      default:
        return null;
    }
  };
  const [changeCard, setChangeCard] = useState(false);
    const [stripeData, setStripeData] = useState(null);
    const [country, setCountry] = useState('US');
    const [stripeElements, setStripeElements] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [cardFeilds, setCardFeilds] = useState({
        cardNumber: true,
        cardExpiry: true,
        cardCvc: true,
        userName: true
    });
    const [errors, setErrors] = useState({
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
        userName: ""
    });
    const [formData, setFormData] = useState({ userName: '',});
    const [isSubmit, setIsSubmit] = useState(false);
    const createCustomer = async (name, email) => {
      const response = await fetch('/api/stripe/create-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
        }),
      });
    
      const data = await response.json();
      if (data.error) {
        console.error('Error creating customer:', data.error);
      } else {
        setCustomerId(data?.customerId);
      }
    };
     useEffect(()=>{
      if(!userData?.customerId){
        const name = `${userData?.first_name}${userData?.last_name?' '+userData?.last_name:''}`;  // Example name
        const email = userData?.email;
        createCustomer(name, email);
      }else{
        setCustomerId(userData?.customerId);
      }
     },[userData])
    const handleCardElementChange = (event, label) => {
        switch (label) {
            case "userName":
                if (!event.target.value) {
                    setErrors({ ...errors, userName: "Name is required" });
                    setCardFeilds({ ...cardFeilds, userName: true });
                } else {
                    setErrors({ ...errors, userName: "" });
                    setCardFeilds({ ...cardFeilds, userName: false });
                    setFormData({ ...formData, userName: event.target.value });
                }
                break;
            case "cardNumber":
                if (event.empty) {
                    setErrors({ ...errors, cardNumber: "Card Number is required" });
                    setCardFeilds({ ...cardFeilds, cardNumber: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardNumber: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardNumber: true });
                } else {
                    setErrors({ ...errors, cardNumber: "" });
                    setCardFeilds({ ...cardFeilds, cardNumber: false });
                }
                break;
            case "cardExpiry":
                if (event.empty) {
                    setErrors({ ...errors, cardExpiry: "Card Expiry is required" });
                    setCardFeilds({ ...cardFeilds, cardExpiry: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardExpiry: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardExpiry: true });
                } else {
                    setErrors({ ...errors, cardExpiry: "" });
                    setCardFeilds({ ...cardFeilds, cardExpiry: false });
                }
                break;
            case "cardCvc":
                if (event.empty) {
                    setErrors({ ...errors, cardCvc: "Card CVC is required" });
                    setCardFeilds({ ...cardFeilds, cardCvc: true });
                } else if (event.error) {
                    setErrors({ ...errors, cardCvc: event.error.message });
                    setCardFeilds({ ...cardFeilds, cardCvc: true });
                } else {
                    setErrors({ ...errors, cardCvc: "" });
                    setCardFeilds({ ...cardFeilds, cardCvc: false });
                }
                break;
            default:
                break;
        }
    };


    const checkAllErrors = () => {
        let err = false;
        let output = Object.entries(cardFeilds);
        // console.log("ErrorsOutputs....",output);
        output.forEach(([key, value]) => {
            if (value) {
                err = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [key]:
                        key === "userName"
                            ? prevErrors.userName
                                ? prevErrors.userName
                                : "Name is required"
                            : key === "cardNumber"
                                ? prevErrors.cardNumber
                                    ? prevErrors.cardNumber
                                    : "Card Number is required"
                                : key === "cardExpiry"
                                    ? prevErrors.cardExpiry
                                        ? prevErrors.cardExpiry
                                        : "Card Expiry is required"
                                    : key === "phone_number"
                                        ? prevErrors.phone_number
                                            ? prevErrors.phone_number
                                            : "Phone number is required"
                                        : key === "postalCode"
                                            ? prevErrors.postalCode
                                                ? prevErrors.postalCode
                                                : "Postal Code is required"
                                            : prevErrors.cardCvc
                                                ? prevErrors.cardCvc
                                                : "Card CVC is required",
                }));
                // console.log("errrr",err,key,value);
            } else {
                setCardFeilds((prevErrors) => ({ ...prevErrors, [key]: false }));
            }
        });
        return err;
    };
    const addCard = async (token,name) => {
      const res = await fetch('/api/stripe/add-card', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, customerId: customerId}),
      });
      const data = await res.json();
      if (data.error) {
        toast(data.error,"error",'',true);
      } else {
        toast("Successfully added card!","success",'',true);
        let updatedData = { ...userData, customerId: customerId,cardUserName:name};
        await db.collection('users').doc(user?.uid).update(updatedData);
        await mutate(`/api/user/${user?.uid}`);
        setIsNext(3,data.card);
      }
    };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripeData || !stripeElements || checkAllErrors()) {
            setIsNext(1,null);
            return;
        }
        const cardElement = stripeElements.getElement(CardNumberElement);
        const { error, token } = await stripeData.createToken(cardElement);
        if (error) {
            let err = {
                error: { message: error.message },
            };
            if (error?.code.includes("number")) {
                handleCardElementChange(err, "cardNumber");
            } else if (error?.code.includes("expiry")) {
                handleCardElementChange(err, "cardExpiry");
            } else if (error?.code.includes("cvc")) {
                handleCardElementChange(err, "cardCvc");
            } else {
                toast(error.message,"error",'',true);
            }
        } else {
          setIsSubmit(true);
          const usercard = {
              username: formData?.userName,
              cardToken: token?.id
          }
          await addCard(usercard?.cardToken,usercard?.username)
        }
    };

    const handleAdd = () => {
        setErrors({
            ...errors, cardNumber: "", cardExpiry: "", cardCvc: "", userName: "", phone_number: "", postalCode: "",
        });
        setChangeCard(false);
        setIsSubmit(false);
        setCardFeilds({ ...cardFeilds, cardNumber: true, cardExpiry: true, cardCvc: true, userName: true, phone_number: true, postalCode: true, });
        setFormData({ ...formData, userName: '', phone_number: '', postalCode: '' });
    };
  return (
    <Box px={6}>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
              {({ stripe, elements }) => {
                  setStripeElements(elements);
                  setStripeData(stripe);
                  return (
                      <>
                          <form className="" onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                              <Stack spacing={2}>
                                      <CardInfo
                                          stripe={stripe}
                                          elements={elements}
                                          errors={errors}
                                          handleCardElementChange={(e, label) =>
                                              handleCardElementChange(e, label)
                                          }
                                          country={country}
                                          setCountry={(value) => setCountry(value)}
                                          formData={formData}
                                          setFormData={() => setFormData({ ...formData, phone_number: '' })}
                                          handlePhoneElement={(value, label) => {
                                              handlePhoneElement(value, label)
                                          }}
                                      />
                                      <Box>
                                          <Button id="submitForm" type="submit" className="btn common-button" disabled={isSubmit} sx={{display:'none'}}>
                                            Next
                                          </Button>
                                      </Box>
                                  </Stack>
                              </Stack>
                          </form>
                      </>
                  );
              }}
          </ElementsConsumer>
        </Elements>
      <PaymentStatus status={payment.status} />
    </Box>
  );
};

export default ElementsForm;
